import { useConversationPermissions } from '../../../../shared/hooks/use-permissions';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { chatListSetter, chatRowSetter, eligibleChatMessagesSetter } from '../states/chat';
import {
  ChatStatuses,
  ConversationTag,
  EligibleMessageTypes,
  InternalNote,
  UpdateNoteMessageEventPayload,
} from '../typings';
import { getNewMessageSocket } from '../../../../infra/sockets';
import {
  Message,
  MessageOrigin,
  SystemMessage,
  CommercePrivacyAccessData,
  MessageTypesEnum,
  NoteMessage,
} from '../typings/message-types';
import { deleteMessageFromCache, sendMessageToCache } from '../states/cache';
import {
  ChatFiltersEventPayload,
  ChatFiltersEventReasonTypes,
  NewMessageChatRow,
  TextMessageData,
} from '../components/chat-list-section/chat-row/typings';
import reportToSentry from '../../../../shared/utils/report-to-sentry';
import { getError } from '../../../../shared/utils/get-error';
import chatRowDataHelper from '../../../../shared/utils/chat-row-data-helper';
import { CHAT_UPDATE_TYPE, ChatUnreadCountBroadcast } from '../typings/chat';
import { getChatUpdatesUseCase } from '../use-cases/get-chat-updates.use-case';
import { useChatListFilter } from './use-chat-list-filter';
import { useSetOpenTestBroadcastModal } from '../../../../shared/hooks/use-open-test-broadcast-modal';
import { getCurrentUserId } from '../../../../infra/firebase/init';
import useSocket from '../../../../shared/hooks/use-socket';
import { customerDetailAtomFamily, internalNotesListAtom } from '../states/customer-details';
import { selectedChatAtom, selectedChatIdAtom } from '../states/selected-chat';
import { FiltersState, chatFilterStatusCountsAtom } from '../states/chat-list';
import { getRandomNumberBetweenRange } from '../../../../shared/utils/number';
import { useReducerAtom } from 'jotai/utils';
import { socketConnectionAtom, socketConnectionReducer } from '../../../../shared/states/socket-connection';
import { SocketConnectionReducerActionTypes, SocketName } from '../../../../shared/typings/socket-connection';
import usePushNotification from '../../../../shared/utils/show-push-notification';
import { FCMNotificationType } from '../../../../shared/typings';
import { getUserChannelData } from '../../../login/v1/utility';

export const useConversationSocket = () => {
  const currentUserId = getCurrentUserId();
  const orgName = getUserChannelData()?.name ?? 'Doubletick';
  const setSendTestBroadcastModal = useSetOpenTestBroadcastModal();
  const [_, dispatch] = useReducerAtom(socketConnectionAtom, socketConnectionReducer);

  const updateCache = useSetAtom(sendMessageToCache);
  const deleteCache = useSetAtom(deleteMessageFromCache);
  const setChatList = useSetAtom(chatListSetter);
  const setEligibleMessageTypes = useSetAtom(eligibleChatMessagesSetter);
  const setChatRow = useSetAtom(chatRowSetter);
  const setSelectedChatId = useSetAtom(selectedChatIdAtom);
  const { getConversationPermissions } = useConversationPermissions();
  const { isFilterSelectRef } = useChatListFilter();
  const updateChatListSectionViaSocketUpdates = useCallback(
    async (lastUpdateTimestamp: number) => {
      try {
        const socketUpdates = await getChatUpdatesUseCase(lastUpdateTimestamp);
        if (socketUpdates) {
          setChatList({
            type: CHAT_UPDATE_TYPE.API_UPDATES,
            updates: socketUpdates,
          });
        }
      } catch (error) {
        reportToSentry(getError(error));
      }
    },
    [setChatList]
  );
  const selectedChat = useAtomValue(selectedChatAtom);
  const phone = selectedChat?.phoneNumber || '';
  const integrationWabaNumber = selectedChat?.integrationWabaNumber || '';
  const customerDetailAtom = useMemo(() => {
    return customerDetailAtomFamily({
      id: `${integrationWabaNumber}::${phone}`,
    });
  }, [integrationWabaNumber, phone]);

  const { showPushNotification } = usePushNotification();
  const setCustomerDetails = useSetAtom(customerDetailAtom);
  const setChatFilterStatusCounts = useSetAtom(chatFilterStatusCountsAtom);
  const setInternalNotesList = useSetAtom(internalNotesListAtom);

  const { socket: conversationSocket } = useSocket(
    getNewMessageSocket,
    updateChatListSectionViaSocketUpdates,
    true,
    (status) =>
      dispatch({
        type: SocketConnectionReducerActionTypes.CONNECTION,
        data: {
          name: SocketName.CONVERSATION,
          status,
        },
      })
  );

  const updateChatReadCount = useCallback(
    (id: string, unreadCount: number) => {
      setChatRow({
        type: CHAT_UPDATE_TYPE.UPDATE,
        partial: true,
        reOrder: false,
        data: {
          id,
          unreadCount,
        },
      });
    },
    [setChatRow]
  );

  const updateChatReadCountRef = useRef(updateChatReadCount);
  updateChatReadCountRef.current = updateChatReadCount;

  const markDone = useCallback(
    (id: string, isDone?: boolean) => {
      if (isDone) {
        setChatList({
          type: CHAT_UPDATE_TYPE.PARTIAL_UPDATE,
          data: {
            id,
            isDone,
          },
        });
        return;
      }
      setChatRow({
        type: CHAT_UPDATE_TYPE.UPDATE,
        partial: true,
        data: {
          id,
          isDone,
        },
      });
      setChatList({
        type: CHAT_UPDATE_TYPE.PARTIAL_UPDATE,
        data: {
          id,
          isDone,
        },
      });
    },
    [setChatRow, setChatList]
  );

  const onIsDoneEvent = useCallback(
    (payload: { chatId: string; isDone: boolean; validUserIds?: string[] }, checkForValidUserIds?: boolean) => {
      const { chatId, isDone, validUserIds } = payload;
      if (checkForValidUserIds && currentUserId && !validUserIds?.includes(currentUserId)) return;
      markDone(chatId, isDone);
    },
    [markDone]
  );

  const onMarkDoneEvent = useCallback(
    ({ chatId, unreadCount, validUserIds }: ChatUnreadCountBroadcast) => {
      if (currentUserId && !validUserIds?.includes(currentUserId)) return;
      updateChatReadCountRef.current(chatId, unreadCount);
    },
    [currentUserId]
  );

  const onChatSlaStatusEvent = useCallback(
    (payload: { chatId: string; isSlaActive?: boolean; slaDueDate?: string; validUserIds?: string[] }) => {
      const { chatId, isSlaActive = false, slaDueDate = '', validUserIds } = payload;

      setChatRow({
        type: CHAT_UPDATE_TYPE.UPDATE,
        partial: true,
        data: {
          id: chatId,
          isSlaActive,
          slaDueDate,
        },
        skipNew: true,
      });
    },
    [setChatRow]
  );

  const onChatSlaStatusEventForAddChat = useCallback(
    (payload: { chatId: string; isSlaActive?: boolean; slaDueDate?: string; validUserIds?: string[] }) => {
      const { chatId, isSlaActive = false, slaDueDate = '', validUserIds } = payload;

      if (currentUserId && !validUserIds?.includes(currentUserId)) return;

      setChatRow({
        type: CHAT_UPDATE_TYPE.UPDATE,
        partial: true,
        data: {
          id: chatId,
          isSlaActive,
          slaDueDate,
        },
      });
    },
    [setChatRow]
  );

  const onPrivacyUpdateEvent = useCallback(
    (payload: CommercePrivacyAccessData) => {
      const message: Message = {
        id: payload.messageId,
        message: payload.message,
        repliesCount: 0,
        deliveryCount: 0,
        erroredCount: 0,
        sentCount: 0,
        messageTime: payload.messageTime,
        senderId: payload.senderId,
        messageOriginType: payload.senderType,
        messageMetadata: null,
        readCount: 0,
        senderUser: payload.chat.senderUser,
        templateId: null,
        totalCount: 1,
        errorMessage: '',
        quickActionButtonStats: [],
        unreadCount: 0,
        noResponseCount: 0,
      };
      updateCache({
        chatId: payload.chatId,
        message,
        partial: false,
        queue: payload.senderType === MessageOrigin.USER,
        fromSocket: true,
      });
    },
    [updateCache]
  );

  const assignTagsEventHandler = useCallback(
    (payload: { chatId: string; tags: ConversationTag[]; validUserIds?: string[] }) => {
      const { chatId, tags, validUserIds } = payload;

      if (currentUserId && !validUserIds?.includes(currentUserId)) return;

      setChatRow({
        type: CHAT_UPDATE_TYPE.UPDATE,
        partial: true,
        data: {
          id: chatId,
          tags,
        },
        skipNew: true,
      });
    },
    [setChatRow]
  );

  const onNewMessageForAddChatSocketEventHandler = useCallback(
    (payload: NewMessageChatRow) => {
      if (currentUserId && !payload.validUserIds?.includes(currentUserId)) return;

      setChatRow({
        type: CHAT_UPDATE_TYPE.UPDATE,
        partial: false,
        // skipNew: isFilterSelectRef.current(),
        data: chatRowDataHelper(payload),
        //sockets are not returning all chat row data like tags etc
        useOldValue: true,
      });
    },
    [
      getConversationPermissions,
      currentUserId,
      setChatRow,
      isFilterSelectRef,
      updateCache,
      setSendTestBroadcastModal,
      setEligibleMessageTypes,
      selectedChat,
    ]
  );

  const onNewMessageSocketEventHandler = useCallback(
    (payload: NewMessageChatRow) => {
      const permissions = getConversationPermissions(payload.chat.id, payload.chat.assignedUserId);

      if (currentUserId !== null && payload.chat.assignedUserId === currentUserId) {
        setSendTestBroadcastModal((prev) => (!prev.open ? prev : { open: false }));
      }
      //FIXME PERMISSION
      if (!permissions.canRead) {
        return;
      }
      if (payload.senderType === MessageOrigin.CUSTOMER) {
        if (selectedChat?.id !== payload.chatId) {
          showPushNotification({
            messageId: payload.messageId,
            collapseKey: '',
            from: '',
            data: {
              chatId: payload.chatId,
              chatString: JSON.stringify(payload.chat),
              type: FCMNotificationType.MESSAGE,
              title: payload.chat.name ?? payload.chat.phoneNumber,
              body: (payload.message as TextMessageData).text ?? 'You have a new message',
              orgId: payload.orgId,
              messageId: payload.messageId,
              senderType: payload.senderType,
            },
          });
        }
      }
      const message: Message = {
        id: payload.messageId,
        message: payload.message as SystemMessage,
        repliesCount: 0,
        deliveryCount: 0,
        erroredCount: 0,
        sentCount: 0,
        replyMessage: payload.replyMessage ?? undefined,
        messageTime: payload.messageTime,
        senderId: payload.senderId,
        messageOriginType: payload.messageOriginType,
        messageMetadata: payload.messageMetadata,
        readCount: 0,
        senderUser: payload.chat.senderUser,
        templateId: null,
        totalCount: 1,
        errorMessage: '',
        unreadCount: 0,
        noResponseCount: 0,
        showReadMore: !(selectedChat?.id === payload.chatId && payload.messageOriginType === MessageOrigin.CUSTOMER),
      };

      if (payload.senderType === MessageOrigin.CUSTOMER) {
        setEligibleMessageTypes({
          chatId: payload.chatId,
          eligibleTypes: [EligibleMessageTypes.ANY],
        });
      }

      if (payload.message.messageType === MessageTypesEnum.NOTE && selectedChat?.id === payload.chatId) {
        setInternalNotesList((prev) => {
          const previousNotesList = prev.data;
          const isNoteAlreadyPresent = previousNotesList?.find((note) => note.id === payload.messageId);

          if (isNoteAlreadyPresent) return prev;
          return {
            ...prev,
            data: [
              {
                id: payload.messageId,
                createdBy: payload.chat.senderUser?.name,
                updatedBy: '',
                dateCreated: String(new Date(payload.messageTime)),
                dateUpdated: '',
                note: (payload.message as NoteMessage).text,
              } as InternalNote,
              ...(previousNotesList ?? []),
            ],
          };
        });
      }

      updateCache({
        chatId: payload.chatId,
        message,
        partial: false,
        queue: payload.senderType === MessageOrigin.USER,
        fromSocket: true,
      });
    },
    [
      getConversationPermissions,
      currentUserId,
      setChatRow,
      isFilterSelectRef,
      updateCache,
      setSendTestBroadcastModal,
      setEligibleMessageTypes,
      selectedChat,
    ]
  );

  const onCustomerUpdatesEvent = useCallback(
    (payload: { isBlocked: boolean; customerId: string; name: string; orgId: string; phoneNumber: string }) => {
      const { isBlocked } = payload;

      setCustomerDetails((customerDetails) => {
        if (customerDetails.data) {
          return {
            ...customerDetails,
            data: {
              ...customerDetails.data,
              customerIsBlocked: isBlocked,
            },
          };
        } else return customerDetails;
      });
    },
    [setCustomerDetails]
  );

  const onChatFilterCountsEvent = useCallback(
    (payload: {
      type: keyof Pick<typeof FiltersState, 'ALL_CHATS' | 'MY_CHATS' | 'UNASSIGNED' | 'SLA_STATUS'>;
      status: ChatStatuses;
      count: string;
    }) => {
      const { type, status, count } = payload;
      setChatFilterStatusCounts((prev) => {
        const updatedChatFilterStatusCounts = [...(prev?.data ?? [])];
        const chatStatusFilterIndex = updatedChatFilterStatusCounts?.findIndex?.(
          (chatStatusFilterCount) => chatStatusFilterCount.type === type
        );
        if (chatStatusFilterIndex > -1) {
          const chatStatusFilterCountIndex = updatedChatFilterStatusCounts?.[
            chatStatusFilterIndex
          ]?.counts?.findIndex?.((chatStatusFilterCount) => chatStatusFilterCount.type === status);
          if (chatStatusFilterCountIndex > -1) {
            updatedChatFilterStatusCounts[chatStatusFilterIndex].counts[chatStatusFilterCountIndex].count = count;
          }
        }
        return { ...prev, data: updatedChatFilterStatusCounts };
      });
    },
    [setChatFilterStatusCounts]
  );

  const removeChat = useCallback(
    (chatId: string, validUserIds?: string[], key?: string) => {
      if (currentUserId && !validUserIds?.includes(currentUserId)) return;

      setChatRow({ type: CHAT_UPDATE_TYPE.DELETE, id: chatId });
    },
    [setChatRow, setSelectedChatId]
  );

  const onChatFiltersAddChatEventHandler = useCallback(
    (payload: ChatFiltersEventPayload) => {
      switch (payload.eventReason) {
        case ChatFiltersEventReasonTypes.NEW_MESSAGE:
          onNewMessageForAddChatSocketEventHandler(payload.eventPayload);
          break;
        case ChatFiltersEventReasonTypes.IS_DONE:
          onIsDoneEvent(payload.eventPayload, true);
          break;
        case ChatFiltersEventReasonTypes.MARK_READ:
          onMarkDoneEvent(payload.eventPayload);
          break;
        case ChatFiltersEventReasonTypes.CHAT_SLA_STATUS:
          onChatSlaStatusEventForAddChat(payload.eventPayload);
          break;
        case ChatFiltersEventReasonTypes.ASSIGN_TAGS:
          assignTagsEventHandler(payload.eventPayload);
          break;
        default:
          break;
      }
    },
    [
      onNewMessageForAddChatSocketEventHandler,
      onIsDoneEvent,
      onMarkDoneEvent,
      onChatSlaStatusEvent,
      assignTagsEventHandler,
    ]
  );

  const onChatFiltersRemoveChatEventHandler = useCallback(
    (payload: ChatFiltersEventPayload) => {
      switch (payload.eventReason) {
        case ChatFiltersEventReasonTypes.NEW_MESSAGE:
          removeChat(payload.eventPayload.chatId, payload.eventPayload.validUserIds);
          break;
        case ChatFiltersEventReasonTypes.IS_DONE:
          removeChat(payload.eventPayload.chatId, payload.eventPayload.validUserIds);
          break;
        case ChatFiltersEventReasonTypes.MARK_READ:
          removeChat(payload.eventPayload.chatId, payload.eventPayload.validUserIds);
          break;
        case ChatFiltersEventReasonTypes.CHAT_SLA_STATUS:
          removeChat(payload.eventPayload.chatId, payload.eventPayload.validUserIds);
          break;
        case ChatFiltersEventReasonTypes.ASSIGN_TAGS:
          removeChat(payload.eventPayload.chatId, payload.eventPayload.validUserIds);
          break;
        default:
          break;
      }
    },
    [removeChat]
  );

  const onUpdateMessageEventHandler = useCallback(
    (payload: UpdateNoteMessageEventPayload) => {
      const message: Message = {
        id: payload.messageId,
        message: payload.message as SystemMessage,
        repliesCount: 0,
        deliveryCount: 0,
        erroredCount: 0,
        sentCount: 0,
        replyMessage: undefined,
        messageTime: payload.messageTime,
        senderId: payload.senderId,
        messageMetadata: null,
        messageOriginType: MessageOrigin.USER,
        readCount: 0,
        senderUser: payload.chat.senderUser,
        templateId: null,
        totalCount: 1,
        errorMessage: '',
        unreadCount: 0,
        noResponseCount: 0,
      };

      updateCache({
        chatId: payload.chatId,
        message,
        partial: false,
        queue: payload.senderType === MessageOrigin.USER,
        fromSocket: true,
      });
      setInternalNotesList((prev) => {
        const previousNotesList = prev.data;
        const changedListId = prev.data?.find((note) => note.id === payload.messageId)?.id ?? '';

        return {
          ...prev,
          data: previousNotesList?.map((note) =>
            note.id === changedListId
              ? {
                  ...note,
                  note: payload.message.text,
                }
              : note
          ),
        };
      });
    },
    [setInternalNotesList, updateCache]
  );

  const onDeleteMessageEventHandler = useCallback(
    (payload: UpdateNoteMessageEventPayload) => {
      deleteCache({
        chatId: payload.chatId,
        messageId: payload.messageId,
      });
      setInternalNotesList((prev) => {
        const previousNotesList = prev.data;
        const changedListId = prev.data?.find((note) => note.id === payload.messageId)?.id ?? '';
        return {
          ...prev,
          data: previousNotesList?.filter((note) => note.id !== changedListId),
        };
      });
    },
    [deleteCache, setInternalNotesList]
  );

  useEffect(() => {
    /**
     * This event handler adds new messages to the particular chat's message list
     */
    conversationSocket.on('new-message', onNewMessageSocketEventHandler);

    /**
     * This event handler updates the SLA related data coming from socket for specific chats
     */
    conversationSocket.on('chat-sla-status', onChatSlaStatusEvent);

    conversationSocket.on('privacy-update', onPrivacyUpdateEvent);

    /**
     * This event handler updates customer blocking and unblocking
     */
    conversationSocket.on('customer-updates', onCustomerUpdatesEvent);

    /**
     * This event handler adds new Chat row to existing Chat list
     */
    conversationSocket.on('chat-filters-add-chat', onChatFiltersAddChatEventHandler);
    conversationSocket.on('chat-filters-add-chat', onChatFiltersAddChatEventHandler);

    /**
     * This event handler removes any existing Chat row from Chat list
     */
    conversationSocket.on('chat-filters-remove-chat', onChatFiltersRemoveChatEventHandler);

    /**
     * This event handler updates old messages, right now only applicable for notes
     */
    conversationSocket.on('update-message', onUpdateMessageEventHandler);

    /**
     * This event handler updates deletes messages, right now only applicable for notes
     */
    conversationSocket.on('delete-message', onDeleteMessageEventHandler);

    conversationSocket.on('is-done', onIsDoneEvent);

    return () => {
      conversationSocket.off('new-message', onNewMessageSocketEventHandler);
      conversationSocket.off('chat-sla-status', onChatSlaStatusEvent);
      conversationSocket.off('privacy-update', onPrivacyUpdateEvent);
      conversationSocket.off('customer-updates', onCustomerUpdatesEvent);
      conversationSocket.off('chat-filters-add-chat', onChatFiltersAddChatEventHandler);
      conversationSocket.off('chat-filters-remove-chat', onChatFiltersRemoveChatEventHandler);
      conversationSocket.off('is-done', onIsDoneEvent);
      conversationSocket.off('update-message', onUpdateMessageEventHandler);
      conversationSocket.off('delete-message', onDeleteMessageEventHandler);
    };
  }, [
    assignTagsEventHandler,
    conversationSocket,
    onNewMessageSocketEventHandler,
    onPrivacyUpdateEvent,
    onCustomerUpdatesEvent,
    onChatFilterCountsEvent,
    onChatFiltersAddChatEventHandler,
    onChatFiltersRemoveChatEventHandler,
  ]);
};
