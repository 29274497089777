import { memo, useCallback, useEffect, useState, useRef } from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import { AppProtectedRoutes, AppUnprotectedRoutes } from '../routes/auth-routes';
import Loader from '../../../shared/components/molecules/loader';
import { LOADING, MUI_LICENCE_KEY } from '../../../shared/constants';
import { deleteMessagingToken, firebaseAuth, initializeFirebaseApp, messaging } from '../../../infra/firebase/init';
import { onAuthStateChanged, User as FirebaseUser, NextFn } from 'firebase/auth';
import { Provider, useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  isDismissibleStateAtom,
  nextScreenStateAtom,
  requireDemoAtom,
  showLogoutModalAtom,
} from '../../../shared/states/login';
import getRequireDemo from '../../../shared/requests/request-demo';
import { firebaseSignInWithCustomToken } from '../../../infra/auth/firebase-auth';
import { registerAnalytics } from '../../../infra/analytics/init';
import { NotificationsProvider } from '../../../shared/components/molecules/notifications-provider';
import { useAuth } from '../../../shared/hooks/use-auth';
import { useSetProfile } from '../../../shared/hooks/use-profile';
import { useOrganizations, useSelectedOrganisationAtom } from '../../../shared/hooks/use-organizations';
import getRefreshNextState from '../../../shared/modules/demo-account/v1/requests/refresh-next-state';
import { UserAction } from '../../login/v1/typings';
import { NextScreenState } from '../../../shared/typings/screens';
import { getUserDialogContent } from '../../../shared/modules/demo-account/v1/requests/get-user-dialog-content';
import { useSetOpenTestBroadcastModal } from '../../../shared/hooks/use-open-test-broadcast-modal';
import { ROUTES } from '../../../shared/constants/routes';
import { useSubscribedPlan } from '../../renew-plan/v1/hooks/use-subscribed-plan';
import { logoutUseCase } from '../../login/v1/use-cases/logout-use-case';
import { getOrganizations } from '../../../shared/requests/organizations';
import chooseOrganisation from '../../login/v1/requests/choose-organisation';
import { useSetFileType, fetchFileTypes } from '../../../shared/hooks/use-file-types';
import { MessagePayload, onMessage } from 'firebase/messaging';
import { useConversationPermissions } from '../../../shared/hooks/use-permissions';
import { ChatFiltersEventReasonTypes, ChatRowData } from '../../home/v1/components/chat-list-section/chat-row/typings';
import { FCMNotificationType, SocketStatusData } from '../../../shared/typings';
import { useShopifySignupDetailsAtom } from '../../shopify/v1/states';
import { useSearchParams } from 'react-router-dom';
import useScrollbar from '../../../shared/hooks/use-scrollbar';
import { useCustomNavigate } from '../../../shared/hooks/use-conversation-navigate';
import { SendIndividualMessage } from '../typings';
import useSingleWaba from '../../../shared/hooks/use-single-waba';
import { FBInitConfig, FBLoginOptions, FBLoginResponse } from '../../embedded-signup/typings';
import { WabaChannelStatus } from '../../../shared/typings/waba';
import { getChannelPartnerPublicDetails } from '../../login/v1/requests/get-channel-partner-public-details';
import { getUserChannelData, updateUserChannelData } from '../../login/v1/utility';
import useMetaDataUpdater from '../../../shared/hooks/useMetaDataUpdater';
import useNotifications from '../../../shared/hooks/use-notifications';
import checkIfChannelPartner from '../../login/v1/utility/checkIfChannelPartner';
import { useRegisterDevice } from '../../../shared/hooks/use-register-device';
import saveSelectedOrganization from '../../login/v1/utility/saveSelectedOrg';
import { AssigneeUpdatesType, InternalNote } from '../../home/v1/typings';
import usePushNotification from '../../../shared/utils/show-push-notification';
import { deleteMessageFromCache } from '../../home/v1/states/cache';
import { teamMembersAtom } from '../../home/v1/states/team-members';

LicenseInfo.setLicenseKey(MUI_LICENCE_KEY);

// Add Promise.withResolvers polyfill
if (typeof Promise.withResolvers !== 'function') {
  Promise.withResolvers = function withResolvers<T>() {
    let resolve!: (value: T | PromiseLike<T>) => void;
    let reject!: (reason?: any) => void;
    const promise = new Promise<T>((res, rej) => {
      resolve = res;
      reject = rej;
    });
    return { promise, resolve, reject };
  };
}

// Share polyfill with iframes
const sharePolyfillWithIframes = () => {
  const polyfillCode = `
    if (typeof Promise.withResolvers !== 'function') {
      Promise.withResolvers = function withResolvers() {
        let resolve;
        let reject;
        const promise = new Promise((res, rej) => {
          resolve = res;
          reject = rej;
        });
        return { promise, resolve: resolve, reject: reject };
      };
    }
  `;

  // Function to inject polyfill into an iframe
  const injectPolyfillToIframe = (iframe: HTMLIFrameElement) => {
    try {
      if (iframe.contentWindow) {
        const script = iframe.contentWindow.document.createElement('script');
        script.textContent = polyfillCode;
        iframe.contentWindow.document.head.appendChild(script);
      }
    } catch (error) {
      console.warn('Could not inject polyfill into iframe:', error);
    }
  };

  // Observe DOM for new iframes
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        if (node instanceof HTMLIFrameElement) {
          // Wait for iframe to load before injecting
          node.addEventListener('load', () => injectPolyfillToIframe(node));
        }
      });
    });
  });

  // Start observing
  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });

  // Handle existing iframes
  document.querySelectorAll('iframe').forEach((iframe) => {
    if (iframe.contentDocument?.readyState === 'complete') {
      injectPolyfillToIframe(iframe);
    } else {
      iframe.addEventListener('load', () => injectPolyfillToIframe(iframe));
    }
  });

  return () => observer.disconnect();
};

interface CustomEventMap {
  reloadpagedata: CustomEvent<{ refreshType: string }>;
  openAddGroupMemberModal: CustomEvent<{
    groupId: string;
    groupChatId: string;
    groupChatName: string;
  }>;
  openWabaSelector: CustomEvent<Pick<SendIndividualMessage, 'data'>>;
  refreshIntegrationMember: CustomEvent<string>;
}

declare global {
  interface HTMLElement {
    mozRequestFullScreen: (options?: FullscreenOptions) => void;
    webkitRequestFullScreen: (options?: FullscreenOptions) => void;
  }
  interface PromiseConstructor {
    withResolvers<T>(): {
      promise: Promise<T>;
      resolve: (value: T | PromiseLike<T>) => void;
      reject: (reason?: any) => void;
    };
  }

  interface Document {
    addEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void
    ): void;
    removeEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void
    ): void;
    mozFullScreenEnabled: boolean;
    mozFullScreenElement: boolean;
    webkitFullscreenElement: boolean;
    webkitCurrentFullScreenElement: boolean;
    mozCancelFullScreen: () => Promise<void>;
    webkitCancelFullScreen: () => Promise<void>;
    webkitExitFullscreen: () => Promise<void>;
    featurePolicy: {
      features: () => string[];
      allowsFeature: (feature: string) => boolean;
      allowedFeatures: () => string[];
      getAllowlistForFeature: (feature: string) => string[];
    };
  }

  interface Window {
    fbq?: (action: string, eventName: string, params: Record<string, string>) => void;
    FB?: {
      init?: (config: FBInitConfig) => void;
      login?: (callback: (response: FBLoginResponse) => void, options: FBLoginOptions) => void;
    };
    fbAsyncInit?: () => void;
    socketStatus?: SocketStatusData;
    Canny?: (action: string, options?: Record<string, string>) => void;
    printState?: () => void;
  }
}

window.socketStatus = {};

const REDIRECT_TO_HOME_FROM: string[] = [
  ROUTES.BILLING_DETAILS,
  ROUTES.COMPANY_DETAILS,
  ROUTES.EMAIL_VERIFICATION,
  ROUTES.NEXT_STEPS,
  ROUTES.RENEW_ACCOUNT,
  ROUTES.LOGIN,
  ROUTES.SIGNUP,
];

const App = memo(() => {
  const navigate = useCustomNavigate();
  const [searchParams] = useSearchParams();
  const { authState, setAuthState } = useAuth();
  const [isLoadingConfig, setIsLoadingConfig] = useState<boolean>(false);
  const [requireDemo, setRequireDemo] = useAtom(requireDemoAtom);
  const setShowLogoutModal = useSetAtom(showLogoutModalAtom);
  const setNextScreenState = useSetAtom(nextScreenStateAtom);
  const setIsDismissibleState = useSetAtom(isDismissibleStateAtom);
  const [loadingNextSteps, setLoadingNextSteps] = useState(false);
  const { fetchOrganizations } = useOrganizations();
  const [cacheKey, setCacheKey] = useState('');
  const setUserProfile = useSetProfile();
  const [selectedOrg, setSelectedOrganization] = useSelectedOrganisationAtom();
  const setSendTestBroadcast = useSetOpenTestBroadcastModal();
  const { verifySubscribedPlan, loading } = useSubscribedPlan(false);
  const fetchOrganizationsRef = useRef(fetchOrganizations);
  const loadingNextStepsRef = useRef(loadingNextSteps);
  const verifySubscribedPlanRef = useRef(verifySubscribedPlan);
  const setFileTypes = useSetFileType();
  const refreshNextScreenStep = useRef(false);
  const setShopifySignupDetails = useShopifySignupDetailsAtom()[1];
  const { wabaIntegrationsFetcher } = useSingleWaba(WabaChannelStatus.ACTIVE);
  const wabaIntegrationsFetcherRef = useRef(wabaIntegrationsFetcher);
  const { updateMetaData } = useMetaDataUpdater();
  const { addNotification } = useNotifications();
  const deleteCache = useSetAtom(deleteMessageFromCache);
  const { data: teamMembers = [] } = useAtomValue(teamMembersAtom);

  useEffect(() => {
    setShopifySignupDetails({
      firstName: searchParams.get('firstName') || '',
      lastName: searchParams.get('lastName') || '',
      orgName: searchParams.get('orgName') || '',
      designation: searchParams.get('designation') || '',
      websiteUrl: searchParams.get('websiteUrl') || '',
      email: searchParams.get('email') || '',
      shopId: searchParams.get('shopId') || '',
    });
  }, []);

  // useEffect(() => {
  //   const getSetPublicConfig = async () => {
  //     setIsLoadingConfig(true);
  //     try {
  //       const config = await getChannelPartnerPublicDetails();
  //       if (config?.name) {
  //         updateMetaData(config?.name, config?.logoUrl);
  //       }
  //       setIsLoadingConfig(false);
  //       updateUserChannelData({
  //         name: config?.name,
  //         logoUrl: config.logoUrl,
  //         privacyLink: config.privacyLink,
  //         tearOfServiceLink: config.tosLink,
  //         featureFlags: config.featureFlags,
  //         recapthcaSiteKey: config.recapthcaSiteKey,
  //         firebaseApiKey: config?.firebaseWebsiteConfig?.apiKey,
  //       });
  //       if (config?.firebaseWebsiteConfig?.apiKey) {
  //         initializeFirebaseApp(config?.firebaseWebsiteConfig?.apiKey);
  //       }
  //     } catch (error) {
  //       setIsLoadingConfig(false);
  //     }
  //   };
  //   if (checkIfChannelPartner()) {
  //     getSetPublicConfig();
  //   }
  // }, []);

  fetchOrganizationsRef.current = fetchOrganizations;
  loadingNextStepsRef.current = loadingNextSteps;
  verifySubscribedPlanRef.current = verifySubscribedPlan;

  const { isAuthReady, isCheckingAuth, isOrgSelected, isIntegrationEnabled, isPermissionsReady, makePayment } =
    authState;

  useRegisterDevice({
    isAuthReady,
    isOrgSelected,
  });

  useEffect(() => {
    if (!selectedOrg?.isDemo) return;

    const handleDemoDialogContent = async () => {
      const demoDialogContent = await getUserDialogContent();

      demoDialogContent?.allowedActions.some((actionItem) => {
        if (actionItem.action === UserAction.SEND_AND_RECEIVE_MESSAGES && !actionItem.checked) {
          setSendTestBroadcast({
            open: true,
          });
          return true;
        }

        return false;
      });
    };

    handleDemoDialogContent();
  }, [selectedOrg?.isDemo]);

  const handleNextStepScreen = useCallback(async () => {
    if (refreshNextScreenStep.current || !isOrgSelected) return;
    setLoadingNextSteps(true);

    const { nextScreenState, isDismissible } = await getRefreshNextState();

    setNextScreenState(nextScreenState);
    setIsDismissibleState(isDismissible);

    refreshNextScreenStep.current = true;

    if (nextScreenState === NextScreenState.BILLING_DETAILS) {
      navigate({ pathname: ROUTES.BILLING_DETAILS });
      setLoadingNextSteps(false);
      return;
    }

    if (nextScreenState === NextScreenState.COMPANY_DETAILS) {
      navigate({ pathname: ROUTES.COMPANY_DETAILS });
      setLoadingNextSteps(false);
      return;
    }

    if (nextScreenState === NextScreenState.EMAIL_VERIFICATION) {
      navigate({ pathname: ROUTES.EMAIL_VERIFICATION });
      setLoadingNextSteps(false);
      return;
    }

    if (nextScreenState === NextScreenState.NEW_INTEGRATION) {
      navigate({ pathname: ROUTES.NEXT_STEPS });
      setLoadingNextSteps(false);
      return;
    }

    if (nextScreenState === NextScreenState.MAIN_SCREEN) {
      const canMakePayment = await verifySubscribedPlanRef.current();
      if (makePayment || canMakePayment) {
        navigate({ pathname: ROUTES.RENEW_PLAN });
        setLoadingNextSteps(false);
        return;
      }

      if (REDIRECT_TO_HOME_FROM.includes(window.location.pathname)) {
        navigate({ pathname: ROUTES.DASHBOARD.replace('/*', '/') }, { replace: true });
        setLoadingNextSteps(false);
        return;
      }

      setLoadingNextSteps(false);
      return;
    }

    setLoadingNextSteps(false);
  }, [isOrgSelected, setNextScreenState, setIsDismissibleState, makePayment, navigate, verifySubscribedPlanRef]);

  const choosingOrgWithQueryToken = useRef(false);

  const { GlobalScrollbar } = useScrollbar();

  const checkTokenAndChooseOrg = useCallback(
    async (user: FirebaseUser | null) => {
      const queryParams = new URLSearchParams(window.location.search);
      const idToken = queryParams.get('token') ?? '';
      const version = queryParams.get('version') ?? '1';
      // got idToken in params from website
      if (!idToken || choosingOrgWithQueryToken.current || version === '1') {
        return;
      }

      choosingOrgWithQueryToken.current = true;

      try {
        // When coming from | website -> logout(keep on same route)
        if (user) {
          try {
            await logoutUseCase();
          } catch (error) {
            addNotification({
              type: 'error',
              message: (error as Error).message ?? 'Failed to logout, please try again.',
            });
            return;
          }
        }

        const authOptions = {
          headers: {
            Authorization: idToken,
          },
        };

        const orgs = await getOrganizations({
          options: {
            ...authOptions,
          },
          authRequired: false,
        });

        if (orgs.length === 1) {
          selectedOrgRef.current = orgs[0];
        } else if (orgs.length > 1) {
          const orgsWithOwner = orgs.filter((org) => org.isOwner);

          if (orgsWithOwner.length === 1) {
            selectedOrgRef.current = orgsWithOwner[0];
          }

          if (orgsWithOwner.length > 1) {
            selectedOrgRef.current = null;
          }
        }

        if (selectedOrgRef.current) {
          const { user } = await chooseOrganisation(selectedOrgRef.current.orgId, {
            options: { ...authOptions },
            authRequired: false,
          });

          await firebaseSignInWithCustomToken(user.token);

          setSelectedOrganization(() => selectedOrgRef.current);
          const data = await registerAnalytics(selectedOrgRef.current.orgId, orgs);

          setUserProfile(() => data?.profile);

          setAuthState({
            isAuthReady: true,
            isCheckingAuth: false,
            isOrgSelected: true,
            isIntegrationEnabled: true,
            isPermissionsReady: true,
            makePayment: false,
          });

          choosingOrgWithQueryToken.current = false;

          return;
        }
      } catch (error) {
        choosingOrgWithQueryToken.current = false;
        navigate({ pathname: ROUTES.LOGIN.replace('/*', '') });
        return;
      }
    },
    [setAuthState, setSelectedOrganization, setUserProfile]
  );

  const selectedOrgRef = useRef(selectedOrg);

  const handleAuthState = useCallback(
    async (user: FirebaseUser | null) => {
      await checkTokenAndChooseOrg(user);
      if (selectedOrgRef.current || choosingOrgWithQueryToken.current) return;

      const { claims } =
        ((await user?.getIdTokenResult()) as {
          claims: { orgId?: string; user_id?: string; phone?: string };
        }) ?? {};

      if (!claims) {
        setCacheKey(Date.now().toString());
        setAuthState({
          isAuthReady: false,
          isCheckingAuth: false,
          isOrgSelected: false,
          isIntegrationEnabled: false,
          isPermissionsReady: false,
          makePayment: false,
        });

        try {
          await deleteMessagingToken();
        } catch (error) {
          // Do nothing
        }

        return;
      }

      const { orgId } = claims;

      if (!orgId) {
        setCacheKey(Date.now().toString());
        setAuthState({
          isAuthReady: true,
          isCheckingAuth: false,
          isOrgSelected: false,
          isIntegrationEnabled: false,
          isPermissionsReady: false,
          makePayment: false,
        });
        return;
      }

      const [organizations, canMakePayment] = await Promise.all([
        fetchOrganizationsRef.current(true),
        verifySubscribedPlanRef.current(),
        wabaIntegrationsFetcherRef.current(),
      ]);

      setCacheKey(Date.now().toString());
      const data = await registerAnalytics(orgId, organizations);
      const selectedOrganization = organizations.find(({ orgId: id }) => id === orgId);

      setUserProfile(() => data?.profile);
      if (data?.profile?.orgId) {
        saveSelectedOrganization(data?.profile?.orgId)
          .then(() => {
            console.log('Selected organization saved successfully.');
          })
          .catch((error) => {
            console.error('Failed to save selected organization:', error);
          });
      } else {
        console.log('No org id found');
      }

      try {
        const fileTypesData = await fetchFileTypes();
        setFileTypes(fileTypesData);
      } catch (error) {
        // Do nothing
      }

      setAuthState({
        isAuthReady: true,
        isCheckingAuth: false,
        isOrgSelected: !!selectedOrganization,
        isIntegrationEnabled: true,
        isPermissionsReady: true,
        makePayment: !!canMakePayment,
      });

      if (selectedOrganization) {
        setSelectedOrganization(selectedOrganization);
      }
    },
    [checkTokenAndChooseOrg, setUserProfile, setFileTypes, setAuthState, setSelectedOrganization]
  );

  const { getConversationPermissions } = useConversationPermissions();
  const { showPushNotification } = usePushNotification();

  const firebaseMessageHandler: NextFn<MessagePayload> = useCallback(
    async (payload) => {
      if ('Notification' in window) {
        try {
          const { data = {} } = payload;

          const pathname = window.location.pathname;
          const orgName = getUserChannelData()?.name ?? 'Doubletick';
          const selectedChatPhoneNumber = pathname.split('/').pop();

          const {
            chatId = '',
            newUserId = '',
            notificationTime = '',
            chatString,
            type,
            silent = 'false',
            noteId = '',
          } = data;
          if (data.orgId !== selectedOrg?.orgId) {
            // notification skip
            return;
          }

          const isSilentAssigneeUpdate = silent === 'true' && type === FCMNotificationType.CHAT_ASSIGNEE_UPDATED;
          const isMessage = type === FCMNotificationType.MESSAGE;
          const isChatAssignedUpdate = type === FCMNotificationType.CHAT_ASSIGNED;
          const isNewCustomerNoteUpdate = type === FCMNotificationType.CUSTOMER_NOTE;
          const isCustomerNoteDeleteUpdate = type === FCMNotificationType.CUSTOMER_NOTE_DELETED;

          let body = '';

          if (isSilentAssigneeUpdate) {
            const payload = {
              eventReason: ChatFiltersEventReasonTypes.ASSIGNEE_UPDATES,
              eventPayload: {
                chatId,
                notificationTime,
                userId: newUserId,
                type: newUserId ? AssigneeUpdatesType.ASSIGNED : AssigneeUpdatesType.UNASSIGNED,
              },
            };
            document.dispatchEvent(
              new CustomEvent('assignee-update-silent', {
                detail: payload,
              })
            );
            return;
          }

          if (isCustomerNoteDeleteUpdate) {
            deleteCache({
              chatId,
              messageId: noteId,
            });
            return;
          }

          if (isNewCustomerNoteUpdate) {
            const { body: note } = data;
            body = note;
          }

          let savedPhoneNo = '';
          let savedChatTypeId = '';
          let savedWaba = '';
          if (isMessage) {
            const { assignedUserId = '', phoneNumber, chatTypeId } = (JSON.parse(chatString) as ChatRowData) ?? {};

            const isAccessible = await getConversationPermissions(chatId, assignedUserId);

            if (!isAccessible || selectedChatPhoneNumber === phoneNumber) {
              return;
            }

            savedPhoneNo = phoneNumber;
            savedChatTypeId = chatTypeId;
          }

          if (isChatAssignedUpdate) {
            const { phoneNumber, integrationWabaNumber } = (JSON.parse(chatString) as ChatRowData) ?? {};

            if (selectedChatPhoneNumber === phoneNumber) {
              return;
            }
            savedPhoneNo = phoneNumber;
            savedWaba = integrationWabaNumber;
          }

          if (savedWaba === '' && isMessage) {
            try {
              const { integrationWabaNumber } = (JSON.parse(chatString) as ChatRowData) ?? {};
              savedWaba = integrationWabaNumber;
            } catch (error) {
              console.error('Error parsing chatString:', error);
            }
          }

          showPushNotification({
            messageId: payload.messageId,
            collapseKey: '',
            from: '',
            data: {
              ...data,
              title: data.title ?? orgName,
              body,
            },
          });
        } catch (error) {
          // Handle error
        }
      }
    },
    [deleteCache, getConversationPermissions, selectedOrg?.orgId, showPushNotification]
  );

  useEffect(() => {
    if (!isAuthReady || !isOrgSelected) return;

    const unsubscribe = onMessage(messaging, firebaseMessageHandler);

    return unsubscribe;
  }, [firebaseMessageHandler, isAuthReady, isOrgSelected]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, handleAuthState);

    return unsubscribe;
  }, [handleAuthState]);

  const checkAuth = useCallback(async () => {
    try {
      setRequireDemo((demo) => ({ ...demo, loading: true }));
      const requireDemoData = await getRequireDemo(true);

      setRequireDemo((demo) => ({
        ...demo,
        data: requireDemoData,
        loading: false,
      }));

      if (!requireDemoData.requireDemo) {
        const { token, firstTimeSetup, chooseOrganizations } = requireDemoData;

        if (!token) {
          setAuthState({
            isAuthReady: false,
            isCheckingAuth: false,
            isOrgSelected: false,
            isIntegrationEnabled: false,
            isPermissionsReady: false,
            makePayment: false,
          });
        }

        await firebaseSignInWithCustomToken(token);

        if (!firstTimeSetup && !chooseOrganizations) {
          setAuthState({
            isAuthReady: true,
            isCheckingAuth: false,
            isOrgSelected: true,
            isIntegrationEnabled: false,
            isPermissionsReady: false,
            makePayment: false,
          });
        }

        setRequireDemo((demo) => ({
          ...demo,
          data: requireDemoData,
          loading: false,
        }));
        return;
      }
    } catch (error) {
      setAuthState({
        isAuthReady: true,
        isCheckingAuth: false,
        isOrgSelected: false,
        isIntegrationEnabled: false,
        isPermissionsReady: false,
        makePayment: false,
      });

      setRequireDemo((demo) => ({
        ...demo,
        loading: false,
        error: error as Error,
      }));
    }
  }, [setAuthState, setRequireDemo]);

  useEffect(() => {
    if (!authState.isAuthReady) {
      return;
    }

    setShowLogoutModal(false);
    handleNextStepScreen().then(() => {});
  }, [authState.isAuthReady, handleNextStepScreen]);

  useEffect(() => {
    if (authState.isAuthReady && !authState.isOrgSelected) {
      // checkAuth();
    } else {
      setRequireDemo((demo) => ({
        ...demo,
        loading: false,
      }));
    }
  }, [authState.isAuthReady, authState.isOrgSelected, checkAuth, setShowLogoutModal, setRequireDemo]);

  // Add effect to initialize polyfill sharing
  useEffect(() => {
    const cleanup = sharePolyfillWithIframes();
    return cleanup;
  }, []);

  if (
    isLoadingConfig ||
    isCheckingAuth ||
    requireDemo.loading ||
    loadingNextStepsRef.current ||
    loading ||
    choosingOrgWithQueryToken.current
  ) {
    return <Loader size={32} secondary={LOADING} />;
  }

  // if (isAuthReady && isOrgSelected && (makePayment || isRenewal)) {
  //   return <AppPaymentRoutes />;
  // }

  if (!isAuthReady || !isOrgSelected || !isIntegrationEnabled) {
    return (
      <>
        <GlobalScrollbar />
        <AppUnprotectedRoutes />
      </>
    );
  }

  if (isAuthReady && isOrgSelected && isPermissionsReady && isIntegrationEnabled) {
    return (
      <Provider key={cacheKey}>
        <GlobalScrollbar />
        <NotificationsProvider maxNotifications={5} />
        <AppProtectedRoutes />
      </Provider>
    );
  }

  return <Loader size={32} secondary={LOADING} />;
});

export default App;
