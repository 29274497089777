export enum ROUTES {
  CHANNEL_MANAGEMENT = '/v1/channels',
  PUSH_NOTIFICATIONS = '/v1/push-notifications',
  CHANNEL_MEMBER_MANAGEMENT = '/v1/channels/:channelId/members',
  CREATE_TEMPLATE = '/v1/templates/new',
  EDIT_TEMPLATE = '/v1/templates/edit',
  DUPLICATE_TEMPLATE = '/v1/templates/duplicate',
  CONVERSATIONS = '/conversations',
  CUSTOMER_CHAT = '/messages/:phone',
  CHANNEL_CHAT = '/channels/:channel',
  LOGIN = '/login',
  SIGNUP = '/signup/*',
  SIGN_IN = '/signin/*',
  NEXT_STEPS = '/v1/next-steps',
  BILLING_DETAILS = '/billing-details',
  COMPANY_DETAILS = '/company-details',
  EMAIL_VERIFICATION = '/v1/email-verification',
  RENEW_PLAN = '/renew-plan',
  RENEW_ACCOUNT = '/account/renew',
  SEND_TEST_BROADCAST = '/send-test-broadcast',
  OTP_SCREEN = '/otp-screen/*',
  TEAM_MANAGEMENT_V1 = '/v1/team-management',
  TEAM_MANAGEMENT = '/v2/team-management',
  TEAM_MANAGEMENT_INVITES = '/v1/team-management/invites/:status',
  TEMPLATES = '/v1/templates',
  BOTS = '/v1/bots',
  BOT_LOGS = '/v1/bots/logs/:botId',
  SETTINGS = '/v1/settings',
  SETTINGS_PROFILE = '/profile',
  SETTINGS_CUSTOM_FIELDS = '/custom-fields',
  SETTINGS_TAGS_MANAGEMENT = '/tag-management',
  SETTINGS_WEBHOOKS = '/webhooks',
  SETTINGS_WIDGETS = '/widgets',
  SETTINGS_DEVELOPER_API = '/developer-api',
  SETTINGS_IMPORT_WHATSAPP_CHAT = '/import-whatsapp-chat',
  SETTINGS_ACTIVITY_LOGS = '/activity-logs',
  SETTINGS_INTEGRATION = '/integration',
  SETTINGS_BILLING = '/billing',
  SETTINGS_SLA = '/sla',
  SETTINGS_WABA_CHANNELS = '/waba-channels',
  SETTINGS_QUICK_REPLIES = '/quick-reply',
  SETTINGS_AI_BOT = '/ai-bot',
  SETTINGS_SEND_TEMPLATE_MESSAGES = '/send-template-messages',
  SETTINGS_ROLES = '/roles',
  CUSTOMERS = '/v1/customers',
  ANALYTICS = '/v1/analytics',
  ENTERPRISE_ANALYTICS = '/v1/enterprise-analytics',
  REPORTS = '/v1/reports',
  SCHEDULED_BROADCASTS = '/v1/scheduled-broadcasts',
  BOT_BUILDER = '/v1/bot-builder/:botId',
  DIALOG_CALLBACK = '/v1/callback/360',
  SIGN_IN_WITH_CUSTOM_TOKEN = '/callback/payment',
  REQUESTS = '/requests',
  AUTO_LOGIN = '/a/:phoneNumber/:otp',
  SHOPIFY_SIGNUP = '/shopify/*',
  AI_BOT = '/ai-bot',
  EMBEDDED_SIGNUP = '/embedded-signup',
  EMBEDDED_SIGNUP_GUPSHUP = '/embedded-signup/gupshup',
  EMBEDDED_SIGNUP_VALUE_FIRST = '/embedded-signup/value-first',
  SETTINGS_WORKING_HOURS = '/working-hours',
  EMBEDDED_SIGNUP_EXOTEL = '/embedded-signup/exotel',
  DASHBOARD = '/dashboard',
  TALLY = '/tally',
  TALLY_LOGS = '/tally/logs',
  EMBED = '/embed/*',
  ADDITIONAL_ENTERPRISE_ANALYTICS = '/v1/additional-enterprise-analytics',
  EMBED_LOGIN = '/embed/login/*',
  CONTACT_SUPPORT = '/contact-support',
  EMBED_CONVERSATIONS = '/embed/conversations',
  AD_INSIGHT = '/ad-insight',
}

export enum RoutePages {
  SETTINGS = 'settings',
  AD_INSIGHT = 'ad-insight',
  TEMPLATES = 'templates',
  SCHEDULED_BROADCASTS = 'scheduled-broadcasts',
  CUSTOMERS = 'customers',
  ANALYTICS = 'analytics',
  ENTERPRISE_ANALYTICS = 'enterprise-analytics',
  REPORTS = 'reports',
  BOTS = 'bots',
  PROFILE = 'profile',
  CHANNEL_MANAGEMENT = 'channels',
  TEAM_MANAGEMENT = 'team-management',
  INTEGRATIONS = 'integrations',
  REQUESTS = 'requests',
  AI_BOT = 'ai-bot',
  DASHBOARD = 'dashboard',
  CONVERSATIONS = 'conversations',
}
