import packageDetails from '../../../package.json';
import { getUserChannelData, shouldHideDTDetails } from '../../modules/login/v1/utility';
import { currentEnvironment } from './env';

type AppConfig = {
  appVersion: string;
  iframeHost: string;
  botBuilderHost: string;
  aiBotBuilderHost: string;
  waPartnerId: string;
  mixPanelToken: string;
  miniPaymentsHost: string;
  sentryDsn: string;
  firebaseVapidKey: string;
  recaptchaSiteKey: string;
  cannyAppId: string;
  adInsightsHost: string;
};

const appVersion = packageDetails.version;

let appConfig: AppConfig;

const haveCPRecaptchaKey = getUserChannelData();
const hideDTDetails = shouldHideDTDetails();
const recaptchaKey = haveCPRecaptchaKey?.recapthcaSiteKey ?? '6LfRvlQoAAAAAPRwKLB17C6Q1hS73KNNm4BBUdIl';

const definedEnvironments = [
  'production',
  'production-2',
  'apiStaging',
  'apiStaging2',
  'apiStaging3',
  'stagingWithProd',
  'staging2WithProd',
];

const defaultfirebaseVapidKey =
  currentEnvironment && definedEnvironments.includes(currentEnvironment as string)
    ? 'BE5yadnH2LHES3VWKkyJwYjPFX0IoUXQSx9iPdV7rm7AjaQ4q9hAFYnpbz-JpPCivRSKdsL9UUyfJXPAJfSM3Ow'
    : 'BD4WgTgSuZl2T7kWAoNHd-cKWFJNpuVX2BKDcuMJB0NKLp79a3pDygx7PM8ueIn-LKrDzzvRmfHY9I3DoZvlo2g';

const firebaseVapidKey = hideDTDetails
  ? 'BG_EjdWFm85Ik4I0WDsvvKWN2KzZbcjTlmvJvbB-lRnYiZyDjz_KnEu0W4_YLdHV_ynL9cIVaUxWDmjqusQfHwA'
  : defaultfirebaseVapidKey;

const cannyAppId = '5e1b0a19332031116eb0865a';

if (currentEnvironment === 'production') {
  appConfig = {
    appVersion,
    iframeHost: 'http://localhost:3001',
    botBuilderHost: 'https://flow.doubletick.io',
    aiBotBuilderHost: 'https://ai-bot.doubletick.io',
    miniPaymentsHost: 'https://mini.payments.quicksell.co',
    waPartnerId: 'ickmk2PA',
    mixPanelToken: '2fba977e7c1b9ee5c7262a7dd79f48ad',
    firebaseVapidKey: firebaseVapidKey,
    sentryDsn: 'https://2fa7420588e446179bba83e10a3ec631@o110511.ingest.sentry.io/4504167964540928',
    recaptchaSiteKey: recaptchaKey,
    cannyAppId,
    adInsightsHost: 'https://ad-insight.doubletick.io',
  };
} else if (currentEnvironment === 'production-local') {
  appConfig = {
    appVersion,
    iframeHost: 'http://localhost:3001',
    botBuilderHost: 'http://localhost:3002',
    aiBotBuilderHost: 'http://localhost:3003',
    miniPaymentsHost: 'https://mini.payments.quicksell.co',
    waPartnerId: 'ickmk2PA',
    mixPanelToken: '2fba977e7c1b9ee5c7262a7dd79f48ad',
    firebaseVapidKey: firebaseVapidKey,
    sentryDsn: 'https://2fa7420588e446179bba83e10a3ec631@o110511.ingest.sentry.io/4504167964540928',
    recaptchaSiteKey: recaptchaKey,
    cannyAppId,
    adInsightsHost: 'https://ad-insight.doubletick.io',
  };
} else if (currentEnvironment === 'production-2') {
  appConfig = {
    appVersion,
    iframeHost: 'https://mini.doubletick.io',
    botBuilderHost: 'https://flow.doubletick.io',
    aiBotBuilderHost: 'https://flow-ai.doubletick.io',
    miniPaymentsHost: 'https://mini.payments.quicksell.co',
    waPartnerId: 'ickmk2PA',
    mixPanelToken: '2fba977e7c1b9ee5c7262a7dd79f48ad',
    firebaseVapidKey: firebaseVapidKey,
    sentryDsn: 'https://2fa7420588e446179bba83e10a3ec631@o110511.ingest.sentry.io/4504167964540928',
    recaptchaSiteKey: recaptchaKey,
    cannyAppId,
    adInsightsHost: 'https://ad-insight.doubletick.io',
  };
} else if (currentEnvironment === 'apiStaging') {
  appConfig = {
    appVersion,
    iframeHost: 'https://crm-mini.dev.quicksell.co',
    botBuilderHost: 'https://quickflow.dev.quicksell.co',
    aiBotBuilderHost: 'https://flow-ai.dev.quicksell.co',
    miniPaymentsHost: 'https://mini.payments.quicksell.co',
    waPartnerId: 'ickmk2PA',
    firebaseVapidKey: firebaseVapidKey,
    mixPanelToken: '65c4a7fd57f808f09e2e1694068f2cf0',
    sentryDsn: '',
    recaptchaSiteKey: recaptchaKey,
    cannyAppId,
    adInsightsHost: 'https://ad-insight.doubletick.io',
  };
} else if (currentEnvironment === 'apiStaging2') {
  appConfig = {
    appVersion,
    iframeHost: 'https://crm-mini.dev.quicksell.co',
    botBuilderHost: 'https://quickflow.dev.quicksell.co',
    aiBotBuilderHost: 'https://flow-ai.dev.quicksell.co',
    miniPaymentsHost: 'https://mini.payments.quicksell.co',
    waPartnerId: 'ickmk2PA',
    firebaseVapidKey: firebaseVapidKey,
    mixPanelToken: '65c4a7fd57f808f09e2e1694068f2cf0',
    sentryDsn: '',
    recaptchaSiteKey: recaptchaKey,
    cannyAppId,
    adInsightsHost: 'https://ad-insight.doubletick.io',
  };
} else if (currentEnvironment === 'apiStaging3') {
  appConfig = {
    appVersion,
    iframeHost: 'https://crm-mini-2.dev.quicksell.co',
    botBuilderHost: 'https://quickflow.dev.quicksell.co',
    aiBotBuilderHost: 'https://flow-ai.dev.quicksell.co',
    miniPaymentsHost: 'https://mini.payments.quicksell.co',
    waPartnerId: 'ickmk2PA',
    firebaseVapidKey: firebaseVapidKey,
    mixPanelToken: '65c4a7fd57f808f09e2e1694068f2cf0',
    sentryDsn: '',
    recaptchaSiteKey: recaptchaKey,
    cannyAppId,
    adInsightsHost: 'https://ad-insight.doubletick.io',
  };
} else if (currentEnvironment === 'stagingWithProd') {
  appConfig = {
    appVersion,
    iframeHost: 'https://crm-mini.dev.quicksell.co',
    botBuilderHost: 'https://flow.doubletick.io',
    aiBotBuilderHost: 'https://flow-ai.doubletick.io',
    miniPaymentsHost: 'https://mini.payments.quicksell.co',
    waPartnerId: 'ickmk2PA',
    mixPanelToken: '2fba977e7c1b9ee5c7262a7dd79f48ad',
    firebaseVapidKey: firebaseVapidKey,
    sentryDsn: 'https://2fa7420588e446179bba83e10a3ec631@o110511.ingest.sentry.io/4504167964540928',
    recaptchaSiteKey: recaptchaKey,
    cannyAppId,
    adInsightsHost: 'https://ad-insights.doubletick.dev',
  };
} else if (currentEnvironment === 'staging2WithProd') {
  appConfig = {
    appVersion,
    iframeHost: 'https://crm-mini-2.dev.quicksell.co',
    botBuilderHost: 'https://quickflow.dev.quicksell.co',
    aiBotBuilderHost: 'https://flow-ai.dev.quicksell.co',
    miniPaymentsHost: 'https://mini.payments.quicksell.co',
    waPartnerId: 'ickmk2PA',
    mixPanelToken: '2fba977e7c1b9ee5c7262a7dd79f48ad',
    firebaseVapidKey: firebaseVapidKey,
    sentryDsn: 'https://2fa7420588e446179bba83e10a3ec631@o110511.ingest.sentry.io/4504167964540928',
    recaptchaSiteKey: recaptchaKey,
    cannyAppId,
    adInsightsHost: 'https://ad-insights.doubletick.dev',
  };
} else {
  appConfig = {
    appVersion,
    iframeHost: 'https://crm-mini-2.dev.quicksell.co',
    adInsightsHost: 'https://ad-insights.doubletick.dev',
    botBuilderHost: 'https://quickflow.dev.quicksell.co',
    aiBotBuilderHost: 'https://flow-ai.dev.quicksell.co',
    miniPaymentsHost: 'https://onboarding.crm.dev.quicksell.co',
    waPartnerId: 'ickmk2PA',
    firebaseVapidKey: firebaseVapidKey,
    mixPanelToken: '65c4a7fd57f808f09e2e1694068f2cf0',
    sentryDsn: '',
    recaptchaSiteKey: recaptchaKey,
    cannyAppId,
  };
}

export default appConfig;
